<template>
  <div class=''>
    <div class="center">
      <div class="list">
        <div class="title">{{detail.OrgName}}</div>
        <div class="flex">
          <span>民宿旅馆: {{detail.HotelName}}</span>
          <span>职位: {{detail.Title}}</span>
        </div>
        <div class="flex">
          <span>职业类型: {{detail.AbilityName}}</span>
          <span>招聘人数: {{detail.RecruitNum}}</span>
        </div>
        <div class="flex">
          <span>工作年限: {{detail.WorkYear}}</span>
          <span>学历: {{detail.EduName}}</span>
        </div>

        <div class="flex">
          <span>年龄: {{detail.AgeMin}} ~ {{detail.AgeMax}}</span>
          <span>紧急程度: {{detail.UrgencyDesc}}</span>
        </div>
        <div class="flex">
          <span>月薪: {{detail.SalaryMin}} ~ {{detail.SalaryMax}}</span>
        </div>
        <div class="flex">
          <span>工作地点: {{ detail.WorkAddrName }}</span>
        </div>
        <div class="flex">
          <span>职位描述: {{ detail.Describe }}</span>
        </div>
        <div class="flex">
          <span>技能要求: {{ detail.Skill }}</span>
        </div>
        <div class="flex">
          <span>任职要求: {{ detail.JobRequire }}</span>
        </div>
        <div class="flex">
          <span>职位福利: {{ detail.Welfare }}</span>
        </div>
        <div class="Status"
          style="background-color: #02c08f;"
          v-if="detail.Status == 1">招聘中</div>
        <div class="Status"
          style="background-color: #e6776f;"
          v-if="detail.Status == 2">暂缓招聘</div>
        <div class="Status"
          style="background-color: #d61d10;"
          v-if="detail.Status == 3">招聘结束</div>
      </div>
    </div>
  </div>
</template>

<script>
import { WeGetJobDetail, HGetJob } from "@/api/shop";
import { getOpenId } from "@/utils/auth";
export default {
  data () {
    return {
      detail: {},
    };
  },
  mounted () {
    this.getJobDetail()
  },

  methods: {
    // 获取详情
    getJobDetail () {
      if (this.$route.params.hotelId == 0) {
        WeGetJobDetail({
          jobId: this.$route.params.jobId
        }).then((res) => {
          if (res.data.code == 0) {
            this.detail = res.data.data
          }
        })
      } else {
        HGetJob({
          openID: getOpenId(),
          hotelId: this.$route.params.hotelId,
          jobId: this.$route.params.jobId,
        }).then((res) => {
          if (res.data.code == 0) {
            this.detail = res.data.data
          }
        })
      }

    },
  }
}
</script>
<style scoped>
.list {
  position: relative;
  width: 90%;
  margin: 5%;
  background: #fff;
  border-radius: 8px;
  font-size: 12px;
  color: #333;
  box-shadow: 0 0 5px #dadada;
}

.list .title {
  padding: 10px 20px 5px;
  font-weight: 700;
  font-size: 15px;
  color: #000;
  letter-spacing: 2px;
}

.list .flex {
  display: flex;
  font-size: 14px;
  color: #333;
  padding: 10px 20px 15px;
  justify-content: space-between;
}

.list .flex text:nth-child(1) {
  width: 40%;
}

.list .flex text:nth-child(2) {
  width: 60%;
}

.list .Status {
  position: absolute;
  top: 0px;
  right: 10px;
  /* background: #4b8cff; */
  font-size: 16px;
  padding: 5px 0px;
  color: #fff;
  width: 85px;
  text-align: center;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
</style>